* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Public Sans', sans-serif !important; */
  /* font-family: 'Roboto', sans-serif; */
  font-family: "Be Vietnam Pro", sans-serif !important;
}

@media print {
  body * {
    visibility: hidden;
  }
  .printable-qr {
    visibility: visible;
    width: 500px;
  }
  .printable-qr img {
    max-width: 100%;
    width: 100%;
    visibility: visible;
  }
}

/* loader */
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-bbl,
.loader-bbl:before,
.loader-bbl:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}
.loader-bbl {
  color: #A843A6;
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loader-bbl:before,
.loader-bbl:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader-bbl:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.loader-bbl:after {
  left: 3.5em;
}

@keyframes bblFadInOut {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

/* loader end */

.highlighted-row {
  background-color: #e6e6fe;
  transition: background-color 0.5s ease;
}

.customer-file-upload-input {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  /* height: 1; */
  /* overflow: hidden; */
  /* position: absolute; */
  /* bottom: 0; */
  /* left: 0; */
  /* white-space: nowrap; */
  width: 0px;
  height: 20px;
  border: 1px solid rebeccapurple;
}

.loading-screen {
  background: linear-gradient(113.34deg, #5d50c6 9.64%, #dc419c 92.38%);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.loading-screen .loading-screen-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
}
.loading-screen img {
  height: 80px;
  width: 80px;
  margin-bottom: 1.5rem;
}

/* custom MUI AutoComplete */
.customAutocomplete .MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 2px;
}

/* scrollbar */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgb(215, 214, 214);
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 10px;
  /* transition: background 1s; */
}

::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.hide-scrollbar {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.highlighted-row {
  background-color: rgb(239, 218, 239);
}

.highlight-task-overdue {
  background-color: rgb(246, 222, 222);
}
.highlight-task-completed {
  background-color: rgb(219, 244, 236);
}
